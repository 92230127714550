import React, { useEffect, useState } from 'react';
import { API } from '../../env';
import axios from 'axios';

export default function Loading({ loadingFor, token }) {
  const [progress, setProgress] = useState(0);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, 70); // Increase by 1% every 70 milliseconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const product = JSON.parse(localStorage.getItem('product'));
      const selectedDuration = parseInt(localStorage.getItem('selectedDuration'));
      const currentUrl = window.location.href;
      const urlParts = currentUrl.split('=');
      const total_price = localStorage.getItem('total_price')

      try {
        if (loadingFor === 'activate-site') {
          
          await axios.post(
            API + 'justpay/callback/successful',
            {
              site_id: product.id,
              duration_month: selectedDuration,
              transaction_id: urlParts[1],
              total_price:total_price,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
            );
          await axios.post(API + `activatesite/${product.id}`, {});

          setRedirect(true);
        } else if (loadingFor === 'failed-transaction') {
          await axios.post(API + 'justpay/callback/error', {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setRedirect(true);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [loadingFor, token]);

  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 3000); // Redirect after 3 seconds
    }
  }, [redirect]);

  return (
    <div>
      {loadingFor === 'activate-site' ? (
        <div style={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <p>Activating your site... Please wait!</p>
          <div style={{ width: '30%', backgroundColor: '#f0f0f0', height: '20px', borderRadius: '10px', marginTop: '10px', overflow: 'hidden' }}>
            <div style={{ width: `${progress}%`, height: '100%', backgroundColor: '#06c97e', transition: 'width 0.3s ease' }}></div>
          </div>
        </div>
      ) : (
        <div>
          Processing payment... Please wait!<br />
          Error while making the payment
        </div>
      )}
    </div>
  );
}
