import React from 'react';

export const Delete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.852"
    height="23.202"
    viewBox="0 0 18.852 23.202"
  >
    <path
      id="Icon_metro-bin"
      data-name="Icon metro-bin"
      d="M5.949,9.179v14.5A1.454,1.454,0,0,0,7.4,25.13H20.45a1.454,1.454,0,0,0,1.45-1.45V9.179H5.949ZM10.3,22.23H8.849V12.079H10.3Zm2.9,0h-1.45V12.079H13.2Zm2.9,0H14.65V12.079H16.1Zm2.9,0H17.55V12.079H19Zm3.263-17.4H17.55V3.016a1.091,1.091,0,0,0-1.088-1.088H11.387A1.091,1.091,0,0,0,10.3,3.016V4.828H5.586A1.091,1.091,0,0,0,4.5,5.916V7.729H23.35V5.916a1.091,1.091,0,0,0-1.088-1.088Zm-6.163,0h-4.35V3.4H16.1V4.828Z"
      transform="translate(-4.499 -1.928)"
      opacity="0.3"
    />
  </svg>
);
