export const API = 'https://api.did.ge/api/';
export const WEB = 'https://api.did.ge/';
export const FRONT_URL = 'https://did.ge/'

// export const API = 'https://didge.azurewebsites.net/api/';
// export const WEB = 'https://didge.azurewebsites.net/';
// export const FRONT_URL ='http://localhost:3000/'
// // // // auth/google

// export const API = 'http://localhost:8000/api/';
// export const WEB = 'http://localhost:8000/';