import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PaymentTypeCard } from './PaymentTypeCard';
import exit from '../../assets/images/exit.png';
import './card.scss';
import './paymenttypecard.css';
import { API, WEB } from '../../env';
import { Eye, Heart, Check, Delete, Edit } from '../SVG';

export const paymentType = [
  // {
  //   id: "1",
  //   paymentType: "subscriptionpay" ,
  //   name:"subscription",
  //   descriptionText: "some description text",
  //   duration:1
  // },
  {
    id: "2",
    paymentType: "justpay",
    name: '6 თვე',
    descriptionText: "თქვენი მოწოდებული ინფორმაციით შევსებულ საიტს მიიღებთ 2 სამუშაო დღის ვადაში რედაქტირებისთის.",
    duration: 6,
    discountCoefficient: 1,
    className: 'ordinary-card',
  },
  {
    id: "3",
    paymentType: "justpay",
    name: '12 თვე',
    descriptionText: "თქვენი მოწოდებული ინფორმაციით შევსებულ საიტს მიიღებთ 2 სამუშაო დღის ვადაში რედაქტირებისთის.",
    duration: 12,
    discountCoefficient: 0.8,
    className: 'prefered-card ',
  },
  {
    id: "4",
    paymentType: "justpay",
    name: '24 თვე',
    descriptionText: "თქვენი მოწოდებული ინფორმაციით შევსებულ საიტს მიიღებთ 2 სამუშაო დღის ვადაში რედაქტირებისთის.",
    duration: 24,
    discountCoefficient: 0.7,
    className: 'ordinary-card',
  }
];

export const prices = [35, 49, 69];
export const sizes = ['S', 'M', 'L'];

export const Card = ({
  type,
  site,
  template,
  category,
  onChoose,
  onDelete,
  token,
  list,
}) => {
  const [fav, setFav] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    localStorage.setItem('product', JSON.stringify(site));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.removeItem('product');
  };

  template = site ? site.template : template;
  category = site ? site.category : category;

  const updateFavorite = () => {
    const headers = { Authorization: `Bearer ${token}` };

    axios
      .post(
        API + 'favorite',
        { template: template.id, category: category.id },
        { headers }
      )
      .then(() => setFav(!fav));
  };

  const deleteSite = () => {
    const headers = { Authorization: `Bearer ${token}` };

    axios
      .delete(API + 'site/' + site.id, { headers })
      .then(() => onDelete(site.id));
  };

  return (
    <div key={template.id} className="card-item">
      <a
        className="image-wrap"
        href={template.old_url}
        rel="noreferrer"
        target="_blank"
      >
        <img src={WEB + 'storage/' + template.image} alt="Template" />
      </a>
      <div className="content">
        <div className="head">
          <h3>
            {category.title} {sizes[template.size]} - {prices[template.size]}{' '}
            ლარი
          </h3>
        </div>
        <div className="footer">
          <div className="info">
            <div className="info-item">
              ზომა: <span>{sizes[template.size]}</span>
            </div>
            <div className="info-item">
              ფასი: <span>{prices[template.size]} ლარი</span>
            </div>
          </div>
          <div className="action">
            <a
              href={template.old_url}
              className="action-item"
              rel="noreferrer"
              target="_blank"
            >
              <Eye />
            </a>
            {type === 'choose' && (
              <>
                <button
                  className={['action-item', fav && 'active'].join(' ')}
                  onClick={updateFavorite}
                >
                  <Heart />
                </button>
                <button
                  className="action-item action-item-last choose"
                  onClick={onChoose}
                >
                  <Check />
                  არჩევა
                </button>
              </>
            )}
            {type === 'dashboard' && (
              <div className="action">
                {site.status === 'draft' ? (
                  <>
                    <button
                      className={['action-item', fav && 'active'].join(' ')}
                      onClick={deleteSite}
                    >
                      <Delete />
                    </button>
                    <button
                      className="action-item action-item-last edit"
                      onClick={() => {
                        // console.log(site.template);
                        localStorage.setItem('product', JSON.stringify(site));
                        navigate(`/form/${site.id}`);
                      }}
                    >
                      <Edit />
                      შევსება
                    </button>
                  </>
                ) : site.status === 'pending' ? (
                  <div>
                    <button
                      className="action-item action-item-last"
                      onClick={openModal}
                    >
                      <Edit />
                      შეკვეთა
                    </button>

                    {isModalOpen && (
                      <div className="modal-content">
                        <h1 style={{ maxWidth: "800px", margin: "auto", marginBottom: "100px", marginTop: "50px", lineHeight: "40px" }}>აირჩიეთ გადახდის გეგმა. ფასი მოიცავს საიტის დამზადებას, ჰოსტინგს და ტექნიკურ მხარდაჭერას</h1>

                        <div className='box-container'>
                          {paymentType.map((item) => (
                            <PaymentTypeCard
                              key={item.id} // Remember to provide a unique key for each item when using map
                              id={item.id}
                              name={item.name}
                              descriptionText={item.descriptionText}
                              duration={item.duration}
                              price={prices[template.size]}
                              paymentType={item.paymentType}
                              className={item.className}
                              discountCoefficient={item.discountCoefficient}
                            />
                          ))}
                        </div>

                        <h3 className='modal-content-h3'>თქვენი მოწოდებული ინფორმაციით შევსებულ საიტს მიიღებთ 2 სამუშაო დღის ვადაში რედაქტირებისთის.</h3>

                        <button className="exit" onClick={closeModal}>
                          <img src={exit} alt="Exit" />
                        </button>
                      </div>
                    )}
                  </div>
                ) : site.status === 'active' ? (
                  <> </>
                ) : null}
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

