import React from 'react';

export const Check = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.838"
    height="19.038"
    viewBox="0 0 26.838 19.038"
  >
    <path
      id="Icon_feather-check"
      data-name="Icon feather-check"
      d="M29.3,9,13.282,25.02,6,17.738"
      transform="translate(-4.232 -7.232)"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
  </svg>
);
