import React from 'react';

export const Edit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.131"
    height="22.1"
    viewBox="0 0 23.131 22.1"
  >
    <g
      id="Icon_feather-edit-3"
      data-name="Icon feather-edit-3"
      transform="translate(1 1)"
    >
      <path
        id="Path_161"
        data-name="Path 161"
        d="M18,30H28.566"
        transform="translate(-7.434 -9.9)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_162"
        data-name="Path 162"
        d="M20.348,5.047A2.49,2.49,0,0,1,23.87,8.569L9.2,23.244,4.5,24.418l1.174-4.7Z"
        transform="translate(-4.5 -4.318)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);
