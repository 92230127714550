import React from "react";
import "./Terms.scss";

export const Terms = () => {
  return (
    <div id="terms">
      <h2>შპს დიდ.ჯი-ს მომსახურებით სარგებლობის პირობები</h2>
      <ul>
        <li>
          მიზანი
          <br />
          <br />
          შპს დიდ.ჯი არის ინტერნეტ სერვისების მომწოდებელი კომპანია. მის მთავარ
          საქმიანობას წარმოადგენს ვებ-გვერდების, ე.წ. ‘საიტების’ შექმნა, რომლის
          მეშვეობითაც მომხმარებლები შეძლებენ თავიანთი საქმიანობის შესახებ
          ინფორმაციის ინტერნეტ სივრცეში განთავსებას, გაზიარებას, ცნობადობის
          ამაღლებას და ონლაინ ვიზიტორებთან კომუნიკაციას.
          <br />
          <br />
          ინფორმაცია კომპანიის შესახებ
          <br />
          <br />
          კომპანიის სახელწოდება: შპს „დიდ.ჯი“ რეგისტრირებული მისამართი:
          საქართველო, თბილისი, ჩუღურეთის რაიონი, მიხეილ წინამძღვრიშვილის ქუჩა, N
          89; საიდენტიფიკაციო ნომერი: 402191098 ელ. ფოსტა: info@did.ge ტელეფონი:
          +995 551211844
          <br />
          <br />
          1. ზოგადი დებულებები
          <br />
          <br />
          1.1 წინამდებარე დოკუმენტი (შემდეგში „ზოგადი პირობები“) განსაზღვრავს
          შპს დიდ.ჯი-ს მომსახურებასთან დაკავშირებულ პირობებს და შეიცავს
          ვებ-გვერდზე (www.did.ge) შეკვეთის განხორციელებისა და შესრულების
          შესახებ მომხმარებლის ინფორმირების ყველა არსებით პირობას;
          <br />
          1.2 ზემოთ ჩამოთვლილი მომსახურების გამოყენებით თქვენ ეთანხმებით
          გამოყენების წინამდებარე შეთანხმების (ხელშეკრულების) სტანდარტულ
          პირობებს.
          <br />
          1.3 წინამდებარე დოკუმენტით განსაზღვრული პირობების მიღებით,
          მომხმარებელი ასევე ეთანხმება სერვისის პოლიტიკას კონფიდენციალური
          მონაცემების დამუშავების მიმართ და cookie ფაილების გამოყენების
          პოლიტიკას;
          <br />
          1.4 მომხმარებელი ყურადღებით უნდა გაეცნოს წინამდებარე პირობებს. იმ
          შემთხვევაში, თუ მოხმარებელი არ ეთანხმება მის რომელიმე პირობას, სერვისი
          სთავაზობს მას უარი თქვას შეკვეთის განხორციელებაზე;
          <br />
          1.5. წინამდებარე მომსახურებით სარგებლობის სტანდარტული პირობები
          (შემდგომში - სტანდარტული პირობები) შედგენილია საქართველოს სამოქალაქო
          კანონმდებლობის, ელექტრონული კომუნიკაციების სფეროში მოქმედი
          კანონმდებლობის შესაბამისად და არეგულირებს შემკვეთსა (მომხმარებელსა) და
          შემსრულებელს შორის ურთიერთობებს.
          <br />
          1.6 წინამდებარე ზოგადი წესებისა და პირობების მიხედვით ტერმინებს აქვს
          შემდეგი მნიშვნელობა:
          <br />
          1.6.1 შეკვეთა - მომხმარებლის მიერ დამუშავებული შეკვეთა მომსახურებაზე,
          რომლის გაწევასაც ახორციელებს შემსრულებელი (შპს დიდ.ჯი);
          <br />
          1.6.2 შემსრულებელი - შპს დიდ.ჯი, რომელიც უწევს მომხმარებელს სხვდასხვა
          ტიპის მომსახურებას;
          <br />
          1.6.3 მომხმარებელი - ფიზიკური ან იურიდიული პირი, რომელიც იღებს შპს.
          დიდ.ჯი-სგან მომსახურებას.
          <br />
          1.7 ტერმინები, რომლებიც არ არის განმარტებული სტანდარტული პირობებით,
          განიმარტება საქართველოს კანონმდებლობის შესაბამისად.
          <br />
          <br />
          2. მომსახურების საგანი
          <br />
          <br />
          2.1 მომხმარებლისთვის ვებ-გვერდის დამზადება (მომხმარებლის მიერ წინასწარ
          შერჩეული ნიმუშის შესაბამისად); ულიმიტო განახლების უფასო სერვისი
          (მომსახურების პერიოდის განმავლობაში); ტექნიკური მხარდაჭერა; ჰოსტინგი
          და SSL უსაფრთხოების სერტიფიკატი; SEO - ძიებაში ოპტიმიზაცია.
          <br />
          <br />
          3. შეკვეთის განხორციელების წესი
          <br />
          <br />
          3.1 მომხმარებელის მიერ წინამდებარე შეკვეთა ხორციელდება შეკვეთის გზით
          რომელიც სამი ეტაპისგან შედგება.
          <br />
          <br />
          3.2 მომხმარებელი უნდა იყოს სრულწლოვანი (18 წლის);
          <br />
          3.3 მომხარებელი უნდა დაეთანხმოს „მომსახურებით სარგებლობის სტანდარტულ
          პირობებს“;
          <br />
          3.4 მომხამრებელი უნდა დაეთანხმოს „კონფიდენციალურობისა და მონაცემთა
          დაცვის პოლიტიკას“; 3.5 მომხმარებელი უნდა დაეთანხმოს „cookie ფაილების
          <br />
          გამოყენების პოლიტიკას“;
          <br />
          3.6 მომხმარებელი პასუხისმგებელია შეკვეთის განთავსებისას წარმოდგენილი
          ინფორმაციის შინაარსსა და ნამდვილობაზე;
          <br />
          3.7 თუკი მომხმარებელი განათავსებს არასწორ, ცრუ ან არასრულ ინფორმაციას
          ან თუ შემსრულებელი ჩათვლის რომ არსებობს ინფორმაციის ნამდვილობაში ან
          სისწორეში ეჭვის შეტანის გონივრული საფუძველი იგი უფლებამოსილია
          შეუზღუდოს მომხმარებელს ვებ-გვერდზე წვდომა და აუკრძალოს მისი
          გამოყენება;
          <br />
          3.8 პირველი ეტაპი შეკვეთის განხორციელებისას მომხმარებელმა www.did.ge
          ვებ-გვერდიდან უნდა შეარჩიოს მისთვის სასურველი ნიმუში და ღილაკზე
          „შეკვეთა“ დაწკაპუნებისას უნდა შეიყვანოს მოქმედი მობილურის ნომერი და
          ელექტონული ფოსტა, რომლებიც არის მკაცრად კონფიდენციალური პირადი
          ინფორმაცია და არ ექვემდებარება მესამე პირებისთვის გადაცემას, ასევე
          აირჩიოს მისთვის ხელსაყრელი გადახდის პირობები.
          <br />
          3.9 შეკვეთის მეორე ეტაპისას მომხმარებელი ახორციელებს მის მიერ შეკვეთის
          პირველი ეტაპზე დაფიქსირებულ ელექტრონულ ფოსტაზე შემსრულებლის
          წარმომადგენლის მიერ გაგზავნილი „ფორმის“ შევსებას, მასში მოცემული
          წესების და პირობების შესაბამისად.
          <br />
          3.10 შეკვეთის მესამე ეტაპისას მომხმარებელი ახორციელებს
          შემსრულებლისთვის მისი მოქმედი დომეინის ნეიმ სერვერების (NS)
          გადმომისამართებას.
          <br />
          3.11 შეკვეთის დასრულების შემდეგ მონაცემები მომხმარებლის შესახებ
          რეგისტრირდება შპს დიდ.ჯი-ს მონაცემთა ბაზაში. მონაცემები მომხმარებლის
          შესახებ არ იცვლება და არ რედაქტირება მისი თანხმობის გარეშე;
          <br />
          <br />
          4. შემსრულებლის ვალდებულებები და უფლებამოსილებები შემსრულებელი
          ვალდებულია:
          <br />
          <br />
          4.1 მიაწოდოს მომხმარებელს ინფორმაცია შეკვეთის შესრულების შესახებ;
          <br />
          4.2 მიაწოდოს მომხმარებელს ინფორმაცია მომსახურების მიწოდების
          დაგვიანების შესახებ;
          <br />
          4.3 მიაწოდოს მომხმარებელს ინფორმაცია მომსახურების მიწოდების
          შეუძლებლობის შესახებ;
          <br />
          4.4 დროულად აღმოაჩინოს და აღკვეთოს უკანონო წვდომის მცდელობა
          მომხამრებლის პერსონალურ მონაცემებზე ან/და მისი მოხვედრა იმ პირთა
          ხელში, რომლებსაც არ აქვთ შეხება შეკვეთის შესრულებასთან.
          <br />
          4.5 მომხმარებლის მხრიდან მესამე ეტაპის დასრულების შემდეგ (მას მერე რაც
          მომხმარებლის მხრიდან განხორციელდა მოქმედი დომეინის ნეიმ სერვერების
          (NS) გადმომისამართება 48 საათის განმავლობაში, შეუთანხმოს საბოლოო
          ვარიანტი და მისი თანხმობის მიღების შემდეგ გაუშვას ვებ-გვერდი.
          <br />
          4.6 მომხმარებლის მხრიდან ვებ-გვერდზე ნებისმიერი განახლების შესახებ
          ინფორმაციის მიღებისას: ა) სამუშაო პერიოდში მიღებული ინფორმაციის
          შემთხვევაში ერთი საათის განმავლობაში და ბ) არასამუშაო პერიოდში
          მიღებული ინფორმაციის შემთხვევაში მომდევნო სამუშაო დღის დაწყებიდან 1
          საათის განმავლობაში, განახორციელოს მომხმარებლის ვებ-გვერდის განახლება.
          შემსრულებელი უფლებამოსილია:
          <br />
          4.7 შეკვეთის მეორე ეტაპისას მომხმარებლის მიერ შევსებულ ფორმაში
          შეიტანოს კორექტირებები, როგორც ტექსტობრივ, ასევე ფოტო/ვიდეო მასალის
          ნაწილში. განხორციელებული ცვლილებების შესახებ ინფორმაცია ელექტრონული
          ფოსტის მეშვეობის მიეწოდება მომხმარებელს.
          <br />
          <br />
          5. მომხმარებლის ვალდებულებები და უფლებამოსილებები მომხმარებელი
          ვალდებულია:
          <br />
          <br />
          5.1 ისარგებლოს შეთავაზებული მომსახურებით მოქმედი კანონმდებლობის
          მოთხოვნათა სრული დაცვით;
          <br />
          5.2 ჯეროვნად და კეთილსინდისიერად შეასრულოს მოსმახურების საგებლობის
          ზოგადი წესები და პირობები;
          <br />
          5.3 ვებ-გვერდზე განსათავსებელი ცვლილებების შესახებ შემსრულებელს
          მიაწოდოს ინფორმაცია შეკვეთის მეორე ეტაპზე გამოყენებული ფორმის მეშვებით
          შემსრულებლის ელექტრონულ ფოსტაზე გაგზავნით. მომხმარებელი უფლებამოსილია:
          <br />
          5.4 თუ მომხმარებელს აქვს პრობლემა შეკვეთის მიმდინარეობასთან
          დაკავშირებით, მას შეუძლია დაუკავშირდეს შპს დიდ.ჯი-ს კლიენტთა
          მომსახურების განყოფილებას;
          <br />
          <br />
          6. მომსახურების საფასური და გადახდის პირობები
          <br />
          <br />
          6.1 ვებ-გვერდზე (www.did.ge) მითითებული ფასები მოიცავს ნებისმიერ
          გადასახადს, რომელიც გამომდინარეობს საქართველოში მოქმედი
          კანონმდებლობიდან (გარდა დღგ-სი) და გამოიხატება ეროვნულ ვალუტაში -
          ლარში;
          <br />
          6.2 შერჩეული ნიმუშის შესაბამისი საფასური გამოქვეყნდება შეკვეთის
          „პირველი ეტაპი“-ს შევსებისას „გადახდის გეგმა“ გრაფაში და ავტომატურად
          გამოიყენება სახელშეკრულებო პროცესის ბოლო ეტაპზე.
          <br />
          6.3 მომსახურების გადახდის გეგმა დაყოფილია სამ ნაწილად: 1. ყოველთვიური
          გადასახადი (მომხმარებელიო ყოველთვიურად იხდის ფიქსირებულ გადასახადს);
          2. ექვსი თვის გადასახადი (როდესაც საფასურის გადახდა ხორციელდება ექვსი
          თვის - ერთბაშად); 3. წლიური გადასახადი (როდესაც საფასურის გადახდა
          ხორციელდება ერთი წლის - ერთბაშად).
          <br />
          6.4 კლიენტების მიერ მომსახურების საფასურის გადახდა შესაძლებელია შპს
          დიდ.ჯი-ს ანგარიშზე, (დაფიქსირებულია ინვოისში) ფულის ელექტრონული
          გადარიცხვით.
          <br />
          6.5 საფასურის გადახდის პერიოდი: ინვოისის მიღებიდან 3 სამუშაო დღის
          განმავლობაში. ინვოისის გაგზავნა ხორციელდება მომხმარებლის მიერ
          დაფიქსირებულ ელექტრონულ ფოსტაზე.
          <br />
          6.6 თუ მომხმარებლისგან მომსახურების საფასურის მიღება შეუძლებელია
          ნებისმიერი მიზეზით, მომხმარებლის ვებ-გვერდი გაითიშება გადახდის
          პერიოდის გასვლისთანავე და იგი ვერ შეძლებს მის გამოყენებას.
          <br />
          6.7 გათიშული ვებ-გვერდის აღდგენა შესაძლებელია გათიშვიდან 20 დღის
          განმავლობაში, გადასახდელ თანხას დამატებული ერთი თვის გადასახადის
          ოდენობის საჯარიმო თანხის გადახდის შემდეგ.
          <br />
          6.8 შემსრულებლის მხრიდან მომხმარებლის მიერ გაუქმებული ვებ-გვერდის
          გადახდილი საფასურის უკან დაბრუნება არ ხორციელდება და არ ექვემდებარება
          გასაჩივრებას.
          <br />
          <br />
          7. მხარეთა პასუხისგებლობა
          <br />
          <br />
          7.1 მხარეებს თავიანთი ვალდებულებების შეუსრულებლობისთვის ეკისრებათ
          პასუხისმგებლობა საქართველოს კანონმდებლობის შესაბამისად;
          <br />
          7.2 მომხმარებელი პასუხისმგებელია მონაცემების სწორად მითითებაზე. შპს
          დიდ.ჯი არ აღიარებს პასუხისმგებლობას მომხმარებლის მიერ ამგვარი
          მონაცემების მიუთითებლობაზე ან/და არასწორად მითითებაზე;
          <br />
          7.3 შპს დიდ.ჯი არ აკონტროლებს და არ არის პასუხისმგებელი მომხმარებლის
          მიერ განთავსებულ ინფორმაციაზე და მომხმარებლები თავად არიან
          პასუხისმგებლები ამგვარი ინფორმაციის კანონიერებაზე;
          <br />
          7.4 შპს დიდ.ჯი არ იქნება პასუხისმგებელი მომსახურების გაწევისას არსებულ
          შეფერხებებზე, კავშირის დამყარების შეცდომებზე, ინტერნეტზე წვდომის
          მომსახურების არ ქონაზე, ინტერნეტის შეფერხებაზე ან მის კონტროლს მიღმა
          არსებულ სხვა საკითხებზე;
          <br />
          7.5 შპს დიდ.ჯი არ აღიარებს პასუხისმგებლობას უსაფრთხოებასთან
          დაკავშირებულ ნებისმიერ შეცდომაზე, რაც შესაძლოა წარმოიშვას მომხმარებლის
          კომპიუტერის სისტემის მიერ გამოწვეული ზიანისგან (კომპიუტერული
          აღჭურვილობა და პროგრამები), ან მასში არსებული ფაილების ან
          დოკუმენტებისგან, შემდეგ შემთხვევებში:
          <br />
          7.5.1 მომხმარებლის კომპიუტერის სისტემაში ან მობილურ ტელეფონში
          ვირუსების არსებობისას;
          <br />
          7.5.2 ვებ-ბრაუზერის გაუმართაობისას;
          <br />
          7.5.3 ვადაგასული ვერსიის გამოყენებისას.
          <br />
          7.6 შპს დიდ.ჯი არ ამოწმებს (და არ აქვს შემოწმების ვალდებულება)
          მომხმარებელთა ვინაობას ან მათ მიერ მიწოდებული მონაცემების სისწორეს,
          ნამდვილობას, სისრულეს ან/და ავთენტურობას.
          <br />
          7.7 შპს დიდ.ჯი იტოვებს უფლებას წაშალოს ნებისმიერი უკანონო ან
          შეურაცმყოფელი შეტყობინება წინასწარი გაფრთხილების ან/და შემდგომი
          შეტყობინების გარეშე.
          <br />
          7.8 ნებისმიერი პირი, რომელიც დაარღვევს ზემოთხსენებულ ვალდებულებებს,
          პასუხისმგებელი იქნება მის მიერ გამოწვეულ ზიანზე.
          <br />
          <br />
          8. მომხმარებელთა მიერ ვებ-გვერდის გაუქმება მომხმარებლებს შეუძლიათ
          გააგზავნონ მოთხოვნა ვებ-გვერდის გაუქმებასთან დაკავშირებით, ინფორმაციის
          შპს დიდ.ჯი-ს ელექტრონულ ფოსტაზე (support@did.ge) გაგზავნით.
          <br />
          <br />
          9. განახლება და შეცვლა
          <br />
          <br />
          9.1 შპს დიდ.ჯი იტოვებს წინამდებარე „ზოგადი წესებისა და პირობების“,
          „კონფიდენციალურობის პოლიტიკისა“ და „ქუქის პოლიტიკის“ ნებისმიერ დროს,
          ყოველგვარი წინასწარი შეტყობინების გარეშე შეცვლის უფლებას;
          <br />
          9.2 შპს დიდ.ჯი-ს მომსახურების მიღებამდე მომხმარებლები ყურადღებით უნდა
          გაეცნონ წინამდებარე „ზოგად წესებსა და პირობებს“. ნებისმიერ
          შემთხვევაში, „ზოგად წესებსა და პირობებზე“ დათანხმება აუცილებელი
          ნაბიჯია მომსახურების მიღებისთვის;
          <br />
          9.3 შპს დიდ.ჯი იტოვებს უფლებას განაახლოს, შეცვალოს ან წაშალოს თავის
          ვებ-გვერდზე არსებული ინფორმაცია და პირობების შესახებ, ნებისმიერ დროს,
          ყოველგვარი წინასწარი შეტყობინებისა და პასუხისმგებლობის დადგომის
          გარეშე;
          <br />
          9.4 შპს დიდ.ჯი არ იძლევა გარანტიას, რომ ვებ-გვერდზე შეღწევისას არ
          წარმოიშობა შეფერხებები და რომ ვებ-გვერდი მუდმივად განახლებულ
          მდგომარეობაში იქნება. ამის მიუხედავად, შპს დიდ.ჯი ვალდებულია, გააკეთოს
          ყველაფერი ამგვარი შეცდომების გამოსწორების, კომუნიკაციის აღდგენის და
          ინფორმაციის განახლების მიზნით, გარდა იმ შემთხვევისა, როდესაც ეს
          შესასრულებლად მეტისმეტად რთული ან შეუძლებელია;
          <br />
          <br />
          10. დავის გადაწყვეტის წესი
          <br />
          <br />
          10.1 წინამდებარე პირობების შესრულებასთან დაკავშირებულ ნებისმიერ დავას
          და უთანხმოებას მხარეები გადაწყვეტენ ურთიერთმოლაპარაკების გზით;
          <br />
          10.2 მოლაპარაკების გზით შეთანხმების მიუღწევლობის შემთხვევაში, დავა
          გადაწყდება თბილისის საქალაქო სასამართლოში, საქართველოს მოქმედი
          კანონმდებლობის შესაბამისად სხვა ნებისმიერი ქვეყნის კანონმდებლობის
          გამორიცხვით.
          <br />
          <br />
          11. ხელშეკრულების ძალაში შესვლა
          <br />
          <br />
          11.1. მომხმარებლის მხრიდან წინამდებარე ხელშეკრულების სტანდარტულ
          პირობებზე ელექტრონულად ნების გამოვლენა (თანხმობა) უთანაბრდება პირად
          ხელმოწერას მატერიალურ დოკუმენტზე და გააჩნია იგივე იურიდიული ძალა.
          <br />
          11.2. შეკვეთის პირველი ეტაპის განხორციელებისას, „შეკვეთა“ ღილაკზე
          დაჭერით მომხმარებელი ადასტურებს, რომ სრულად გაეცნო ელექტრონულად
          განთავსებულ წინამდებარე „მომსახურებით სარგებლობის სტანდარტულ
          პირობებს“, ხელშეკრულების შინაარსი მის მიერ აღქმულია ზუსტად და იგი
          სრულად შეესაბამება მის ნებას.
          <br />
          11.3 ხელშეკრულება მოქმედებს 12 თვის ვადით, ვადის ამოწურვის შემდგომ თუ
          მხარეებს არ აქვთ პრეტენზია ერთმანეთის მიმართ ვადის გაგრძელება
          ხორციელდება ავტომატურად დამატებით 12 თვის ვადით.
        </li>
      </ul>
    </div>
  );
};
