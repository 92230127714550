import React, { useState } from 'react';
import axios from 'axios';
import { API, FRONT_URL } from '../../env';
import './paymenttypecard.css';

export const PaymentTypeCard = ({ id, className, paymentType, name, descriptionText, duration, price, discountCoefficient }) => {
  const [phoneNumber, setPhoneNumber] = useState(null); // State to store the phone number input
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false); // State to control when to show the phone number input

  
  const pricePerMonth = Math.round(price * discountCoefficient);
  const amount = Math.round(duration * pricePerMonth).toFixed(2);
  
  console.log(amount)
  
  
  
  const justpay = async () => {
    localStorage.setItem('selectedDuration' , duration)
    localStorage.setItem('total_price', amount)
    const product = JSON.parse(localStorage.getItem('product'));
    const user = JSON.parse(localStorage.getItem('user_data'));
    const email = user.email;
  
    try {
      // const response = await axios.post(API + 'justpay', { amount, email });
      window.location.href = FRONT_URL + 'activate-site?activeButNotPaid';

      
      // Assuming payment is successful and you're redirected back after payment
  
      // Triggering backend to update site status
      
      
      // Rest of your code...
    } catch (error) {
      console.error('Error:', error);
      console.log('An error occurred.');
    }
  };
  

  

  return (
    <div className={`box ${className}`}>
      <div className="heading">{name}</div>
      <div className="circle">₾ {pricePerMonth} თვეში </div>
      {/* <div className="description">{}</div> */}
      <div className="description">სულ {amount} ₾ </div>
      <div className="pay">
        <button
          onClick={() => {justpay();}}
        >
          შეკვეთა
        </button>
      </div>
    </div>
  );
};

